export default {
    watchQuery: true,
    async asyncData({ $axios, route, store, error }) {
        try {
            store.commit('content/setLoading', true);
            store.commit('category/setCategoryItems', []);

            const { data } = await $axios.request({
                method: 'get',
                url: '/api/v2/refresh',
            });
            if (data && Object.keys(data).length > 0) {
                store.commit('user/assignUserData', data);
                store.commit('user/setLoggedIn', true);
            }

            await Promise.all([
                store.dispatch('navigation/load', route),
                store.dispatch('content/load', { route, error }),
            ]);
            if (!store.getters['homeowner/apartments/isLoaded'] && store.getters['user/getLoggedIn']) {
                store.dispatch('homeowner/apartments/load');
            }

            store.commit('product/present');

            return {
                contentData: store.getters['content/getData'],
            };
        } finally {
            if (process.server) {
                store.commit('content/setLoading', false);
            } else {
                setTimeout(() => store.commit('content/setLoading', false), 100);
            }
        }
    },
    transition: {
        beforeLeave() {
            document.documentElement.classList.add('page-transition');
        },
        afterEnter() {
            document.documentElement.classList.remove('page-transition');
        },
    },
};
